import { Columns as AnimatedColumns, Column } from "components/animated";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import React, { Component } from "react";
import { renderHtml, renderText } from "utils/renderHelpers";

import Collage from "slices/Collage";
import Columns from "react-bulma-components/lib/components/columns";
import FullWidthImage from "slices/FullWidthImage";
import Helmet from "react-helmet";
import Hero from "slices/Hero";
import JoinCallout from "slices/JoinCallout";
import Map from "components/Map";
import { animatedChild } from "utils/commonPoses";
import classNames from "classnames/bind";
import getPageMeta from "utils/getPageMeta";
import { graphql } from "gatsby";
import posed from "react-pose";
import styles from "./OfficePage.module.scss";

const cx = classNames.bind(styles);

const AnimatedColumn = posed(Column)(animatedChild);
const HeroFooter = posed(AnimatedColumns)({
  visible: {
    beforeChildren: true,
    staggerChildren: 100,
    delayChildren: ({ animationDelay }) => {
      return animationDelay;
    },
  },
});

class OfficePage extends Component {
  renderSlice = (slice) => {
    if (!slice) {
      return null;
    }
    let Cmp;
    switch (slice.slice_type) {
      case "collage":
        Cmp = Collage;
        break;
      case "full_width_image":
        Cmp = FullWidthImage;
        break;
      case "join_callout":
        Cmp = JoinCallout;
        break;
      default:
        Cmp = null;
        break;
    }
    if (!Cmp) {
      return null;
    }
    return <Cmp {...slice} key={slice.id} />;
  };
  render() {
    const { page, globals } = this.props.data;
    const { body } = page.data;
    const {
      name,
      address,
      phone,
      facebook_url,
      twitter_url,
      linkedin_url,
      instagram_url,
    } = page.data;

    const inquiries = this.props.data.page.data.body[0];

    const inquiriesItems = inquiries.items.filter((item) =>
      item && item.person && item.person.document ? true : false
    );

    // const hasFacebook = facebook_url && facebook_url.url;
    // const hasTwitter = twitter_url && twitter_url.url;
    // const hasLinkedin = linkedin_url && linkedin_url.url;
    // const hasInsta = instagram_url && instagram_url.url;
    // const hasSocial = hasFacebook || hasTwitter || hasLinkedin || hasInsta;
    let facebookUrl =
      facebook_url && facebook_url.url
        ? facebook_url.url
        : globals.data.facebook_url;
    let twitterUrl =
      twitter_url && twitter_url.url
        ? twitter_url.url
        : globals.data.twitter_url;
    let linkedinUrl =
      linkedin_url && linkedin_url.url
        ? linkedin_url.url
        : globals.data.linkedin_url;
    let instagramUrl =
      instagram_url && instagram_url.url
        ? instagram_url.url
        : globals.data.instagram_url;

    const hero = {
      id: "hero",
      primary: {
        heading: {
          html: `<h1>Citizen<br/>${name.text}</h1>`,
        },
        image: page.data.image,
        theme: "red",
      },
      animationDelay: 500,
    };

    return (
      <React.Fragment>
        <Helmet
          title={`${name.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        <Hero
          {...hero}
          compressed={false}
          footerChildren={
            <HeroFooter
              initialPose={"hidden"}
              pose={"visible"}
              animationDelay={2500}
              mobile
              multiline
              className={styles.heroInfo}
            >
              <Columns.Column mobile={{ size: 12 }} tablet={{ size: 10 }}>
                <Columns mobile multiline>
                  {address && (
                    <AnimatedColumn mobile={{ size: 6 }} tablet={{ size: 6 }}>
                      <div className={styles.info}>
                        {renderHtml(address, `aside`, styles.address)}
                      </div>
                    </AnimatedColumn>
                  )}
                  {phone && (
                    <AnimatedColumn mobile={{ size: 6 }} tablet={{ size: 6 }}>
                      <a className={styles.phone} href={`tel:${phone.text}`}>
                        {phone.text}
                      </a>
                    </AnimatedColumn>
                  )}
                  {inquiries && inquiriesItems.length > 0 && (
                    <AnimatedColumn mobile={{ size: 6 }} tablet={{ size: 6 }}>
                      {inquiriesItems.map((item, idx) => {
                        const doc = item.person.document[0];
                        return (
                          <aside
                            key={`inquiryitem-${idx}`}
                            className={cx({ inquiryItem: true, info: true })}
                          >
                            {renderText(item.heading, "h4")}
                            {doc.data.email && (
                              <a
                                href={`mailto:${doc.data.email.text}`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {doc.data.email.text}
                              </a>
                            )}
                          </aside>
                        );
                      })}
                    </AnimatedColumn>
                  )}
                  <AnimatedColumn mobile={{ size: 12 }} desktop={{ size: 4 }}>
                    <h4>Follow us</h4>
                    <ul className={styles.social}>
                      <li>
                        <a
                          title="Facebook"
                          href={facebookUrl}
                          target={`_blank`}
                        >
                          <span className="is-sr-only">Facebook</span>
                          <i className="icon">
                            <FaFacebookF />
                          </i>
                        </a>
                      </li>
                      <li>
                        <a title="Twitter" href={twitterUrl} target={`_blank`}>
                          <span className="is-sr-only">Twitter</span>
                          <i className="icon">
                            <FaTwitter />
                          </i>
                        </a>
                      </li>
                      <li>
                        <a
                          title="LinkedIn"
                          href={linkedinUrl}
                          target={`_blank`}
                        >
                          <span className="is-sr-only">LinkedIn</span>
                          <i className="icon">
                            <FaLinkedinIn />
                          </i>
                        </a>
                      </li>
                      <li>
                        <a
                          title="Instagram"
                          href={instagramUrl}
                          target={`_blank`}
                        >
                          <span className="is-sr-only">Instagram</span>
                          <i className="icon">
                            <FaInstagram />
                          </i>
                        </a>
                      </li>
                    </ul>
                  </AnimatedColumn>
                </Columns>
              </Columns.Column>
            </HeroFooter>
          }
        />
        {body.map((slice) => {
          return this.renderSlice(slice);
        })}
        <section className="section is-paddingless">
          <Map {...page.data.location} />
        </section>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    globals: prismicFoot {
      data {
        facebook_url
        twitter_url
        linkedin_url
        instagram_url
      }
    }
    page: prismicOffice(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url

            dimensions {
              width
              height
            }
          }
          facebook {
            url

            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        image {
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title: name {
          text
        }
        address {
          html
        }
        phone {
          text
        }
        email {
          text
        }
        location {
          latitude
          longitude
        }
        facebook_url {
          url
        }
        twitter_url {
          url
        }
        linkedin_url {
          url
        }
        instagram_url {
          url
        }
        body {
          ... on PrismicOfficeBodyCollage {
            id
            slice_type
            primary {
              theme
              heading {
                text
              }
            }
            items {
              collage_image {
                url
                dimensions {
                  width
                  height
                }

                small {
                  url
                  dimensions {
                    width
                    height
                  }
                }
                thumbnail {
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
          ... on PrismicOfficeBodyFullWidthImage {
            id
            slice_type
            primary {
              theme
              heading {
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicOfficeBodyJoinCallout {
            id
            slice_type
            primary {
              heading {
                text
              }
              paragraph {
                html
              }
              link_label
              link {
                url
                raw {
                  link_type
                  target
                }
              }
            }
          }
          ... on PrismicOfficeBodyInquiryListings {
            slice_type
            items {
              heading {
                text
              }
              person {
                document {
                  uid
                  data {
                    name {
                      text
                    }
                    email {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OfficePage;
