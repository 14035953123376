import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import constants from 'utils/constants';
import styles from './Map.module.scss';

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${
      constants.mapsApiKey
    }&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className={styles.mapContainer} />,
    mapElement: <figure className={styles.map} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
      center={{ lat: props.latitude, lng: props.longitude }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: props.latitude, lng: props.longitude }}
          onClick={props.onMarkerClick}
        />
      )}
    </GoogleMap>
  );
});

export default class Map extends React.PureComponent {
  render() {
    return <MapComponent {...this.props} isMarkerShown={true} />;
  }
}
