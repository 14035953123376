import React, { PureComponent } from 'react';
import { Section } from 'components/animated';
import Container from 'react-bulma-components/lib/components/container';
import Columns from 'react-bulma-components/lib/components/columns';
import classNames from 'classnames/bind';
import styles from './Collage.module.scss';
import { renderFluidImage } from 'utils/imageHelpers';
import Waypoint from 'react-waypoint';
import {
  animatedContainer,
  animatedReveal,
  animatedChild
} from 'utils/commonPoses';
import posed from 'react-pose';
import { validateText } from 'utils/renderHelpers';

const cx = classNames.bind(styles);

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedHeading = posed.h2(animatedChild);
const ImageReveal = posed.figure(animatedReveal);

export default class Collage extends PureComponent {
  state = { visible: false };

  onEnter = () => {
    this.setState({ visible: true });
  };

  render() {
    const { primary, items } = this.props;
    const { theme, layout, heading } = primary;
    const isLTR = layout === 'LTR';
    let l = items.length;
    if (l === 0) {
      return null;
    }
    return (
      <Waypoint
        scrollableAncestor={typeof window === 'undefined' ? null : window}
        onEnter={this.onEnter}
        topOffset={`30%`}
        bottomOffset={`30%`}
      >
        <AnimatedSection
          className={`is-theme-${theme} ${cx({
            section: true,
            [theme]: true
          })}`}
          stagger={250}
          initialPose="hidden"
          pose={this.state.visible ? 'visible' : 'hidden'}
        >
          <Container>
            {validateText(heading) && (
              <AnimatedHeading>{heading.text}</AnimatedHeading>
            )}
            <Columns mobile multiline>
              <Columns.Column
                className={`is-flex-tablet ${
                  isLTR ? 'is-order-1-mobile' : 'is-order-2-mobile'
                } ${cx({ column: true, hasImage: true })}`}
                mobile={{ size: 12 }}
                tablet={{ size: l === 3 ? 7 : 6 }}
              >
                <figure>
                  {renderFluidImage(
                    l === 4
                      ? items[0].collage_image
                      : items[0].collage_image.big
                  )}
                  <ImageReveal
                    className={cx({ imageReveal: true, [theme]: true })}
                  />
                </figure>
              </Columns.Column>
              {l > 1 && (
                <Columns.Column
                  className={`is-flex-tablet ${
                    isLTR ? 'is-order-2-mobile' : 'is-order-1-mobile'
                  } ${cx({ column: true, hasImage: true })}`}
                  mobile={{ size: 12 }}
                  tablet={{ size: l === 3 ? 5 : 6 }}
                >
                  {l === 2 ? (
                    <figure>
                      {renderFluidImage(items[1].collage_image)}
                      <ImageReveal
                        className={cx({ imageReveal: true, [theme]: true })}
                      />
                    </figure>
                  ) : (
                    <Columns mobile multiline className="is-gapless">
                      {l === 3 && (
                        <Columns.Column
                          mobile={{ size: 12 }}
                          tablet={{ size: 12 }}
                        >
                          <Columns mobile multiline>
                            <Columns.Column
                              className={`${styles.hasImage}`}
                              mobile={{ size: 12 }}
                              tablet={{ size: 12 }}
                            >
                              <figure>
                                {renderFluidImage(items[1].collage_image.small)}
                                <ImageReveal
                                  className={cx({
                                    imageReveal: true,
                                    [theme]: true
                                  })}
                                />
                              </figure>
                            </Columns.Column>
                            <Columns.Column
                              className={styles.hasImage}
                              mobile={{ size: 12 }}
                              tablet={{ size: 12 }}
                            >
                              <figure>
                                {renderFluidImage(items[2].collage_image.small)}
                                <ImageReveal
                                  className={cx({
                                    imageReveal: true,
                                    [theme]: true
                                  })}
                                />
                              </figure>
                            </Columns.Column>
                          </Columns>
                        </Columns.Column>
                      )}
                      {l === 4 && (
                        <Columns.Column
                          mobile={{ size: 12 }}
                          tablet={{ size: 12 }}
                        >
                          <Columns mobile multiline>
                            <Columns.Column
                              className={styles.hasImage}
                              mobile={{ size: 12 }}
                              tablet={{ size: 12 }}
                            >
                              <figure>
                                {renderFluidImage(items[1].collage_image.small)}
                                <ImageReveal
                                  className={cx({
                                    imageReveal: true,
                                    [theme]: true
                                  })}
                                />
                              </figure>
                            </Columns.Column>
                            <Columns.Column
                              className={styles.hasImage}
                              mobile={{ size: 12 }}
                              tablet={{ size: 6 }}
                            >
                              <figure>
                                {renderFluidImage(
                                  items[2].collage_image.thumbnail
                                )}
                                <ImageReveal
                                  className={cx({
                                    imageReveal: true,
                                    [theme]: true
                                  })}
                                />
                              </figure>
                            </Columns.Column>
                            <Columns.Column
                              className={styles.hasImage}
                              mobile={{ size: 12 }}
                              tablet={{ size: 6 }}
                            >
                              <figure>
                                {renderFluidImage(
                                  items[3].collage_image.thumbnail
                                )}
                                <ImageReveal
                                  className={cx({
                                    imageReveal: true,
                                    [theme]: true
                                  })}
                                />
                              </figure>
                            </Columns.Column>
                          </Columns>
                        </Columns.Column>
                      )}
                    </Columns>
                  )}
                </Columns.Column>
              )}
            </Columns>
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
